import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { cn } from "../../lib/utils";
import { Calendar } from "../../components/ui/calendar";
import { CalendarIcon, ChevronsUpDown, Check, Download } from "lucide-react";
import { format, isValid, parseISO } from "date-fns";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../components/ui/command";
import { CSVDownload } from "react-csv";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../components/ui/sheet";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { filter } from "lodash";
import { useToast } from "../../components/ui/use-toast";
import { ClipLoader } from "react-spinners";
import SgtinModal from "../../components/SgtinModal";

const pageLimit = 7;
let staticDB = [];
let staticBat = [];
function Sgtin() {
  const [startdate, setSatrtdate] = useState();
  const [enddate, setEndddate] = useState();
  const [openclient, setOpenClient] = useState(false);
  const [valueClient, setClientValue] = useState("");
  const [traceId, setTraceId] = useState("");
  const [elemTarget, setElemTarget] = useState(null);
  const [dataDownload, setDataDownload] = useState([]);
  //
  //
  const [openProd, setOpenProd] = useState(false);
  const [prodValue, setProdValue] = useState("");

  //
  //
  const [openLabel, setOpenlabel] = useState(false);
  const [labelValue, setLabelalue] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const { allClients } = useAuth();
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [tabArr, setTabArr] = useState([]);
  const [prods, setProds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let [pageNumbers, setPageNumbers] = useState([]);
  const { toast } = useToast();
  const getProductsbyKey = async (key) => {
    const response = await axiosPrivate.get(`client/products/${key.clientKey}`);
    return response;
  };

  const handleChange = async (key) => {
    try {
      const prodResponse = await getProductsbyKey({ clientKey: key });
      setProds([...prodResponse.data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const sgtTinheader = [
    { field: "GTIN", key: "GTIN" },
    { field: "BATCH_LOT_NO", key: "BATCH_LOT_NO" },
    { field: "EXP_DATE", key: "EXP_DATE" },
    { field: "SN", key: "SN" },
    { field: "MFG_DATE", key: "MFG_DATE" },
  ];
  const sgtinCsvReport = {
    fileName: `encoded-sgtin-${traceId}`,
    header: sgtTinheader,
    data: dataDownload,
  };
  const handleDownload = () => {
    if (traceId !== "") {
      console.log("fetching sgtins");
      getSgtin.mutate(traceId);
    }
  };
  const getSgtinsByLot = async (traceId) => {
    return await axiosPrivate.get(`label/sgtin/${traceId}`);
  };
  const getSgtin = useMutation((variables) => getSgtinsByLot(variables), {
    onSuccess(data, variables, context) {
      setDataDownload([...data.data.data]);
      elemTarget.target.checked = false;
      setTraceId("");
      setElemTarget(null);
    },

    onError(error) {
      setTraceId("");
      elemTarget.target.checked = false;
      setElemTarget(null);
      if (error.code === "ERR_NETWORK") {
        console.log(error);
      } else if (
        error.response?.status === 403 ||
        error.response?.status === 401
      ) {
        navigate("/", { state: { from: location }, replace: true });
      } else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    },
  });

  function handleParams() {
    const dateEnd =
      enddate !== undefined
        ? format(enddate, "yyyy-MM-dd hh:mm:ss")
        : format(new Date(), "yyyy-MM-dd hh:mm:ss");
    const dateStart =
      startdate !== undefined
        ? format(startdate, "yyyy-MM-dd hh:mm:ss")
        : new Date("2022-01-01");
    const clients = allClients.map((client) => client.name);
    const prods = staticDB.map((prod) => prod.prodName);
    const labels = staticDB.map((label) => label.BATCH_LOT_NO);
    console.log(prods);
    console.log(clients);
    console.log(labels);
    const filterArry = filter(staticDB, (x) => {
      return (
        (valueClient === ""
          ? clients.includes(x.clientName)
          : valueClient === x.clientName.toLowerCase()) &&
        (prodValue === ""
          ? prods.includes(x?.prodName)
          : prodValue === x.prodName) &&
        (labelValue === "" || labelValue === "all"
          ? labels.includes(x.BATCH_LOT_NO)
          : labelValue === x.BATCH_LOT_NO) &&
        new Date(x.createdAt).getTime() >= new Date(dateStart).getTime() &&
        new Date(x.createdAt).getTime() <= new Date(dateEnd).getTime()
      );
    });
    setPageNumbers(() => {
      let count = [];
      for (
        let index = 0;
        index < Math.ceil(filterArry.length / pageLimit);
        index++
      ) {
        count.push(index + 1);
      }
      return count;
    });
    setEventList(filterArry);
  }
  useEffect(() => {
    const offSet = pageLimit * currentPage - pageLimit;
    setTabArr(eventList.slice(offSet, pageLimit + offSet));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, eventList]);

  function handlePageChange(dir) {
    if (dir === "next") {
      setCurrentPage((prev) => prev + 1);
    }
    if (dir === "prev") {
      setCurrentPage((prev) => prev - 1);
    }
  }

  const getAllSgtinPaginated = async (page) => {
    const response = await axiosPrivate.get(
      `label/sgtin/table/?currentPage=${page}&clientKeys=all&prodIds=all`
    );
    return response;
  };
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const location = useLocation();

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((resp) => {
      console.log("geting location ......................");
      console.log(resp);
      if (resp.state === "granted" || resp.state === "prompt") {
        console.log("granted");
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(success, error);
        }
      } else if (resp.state === "denied") {
        navigate("/", { state: { from: location, replace: true } });
      }
    });

    function success(position) {
      setLatitude(`${position.coords.latitude}`);
      setLongitude(`${position.coords.longitude}`);
      console.log(position.coords.latitude);
    }

    function error(err) {
      console.log(err);
      navigate("/", { state: { from: location, replace: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { isLoading } = useQuery({
    queryKey: ["sgtins"],
    queryFn: () => getAllSgtinPaginated(1),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      console.log(data);
      setEventList(data?.data?.data?.row);
      staticDB = data?.data?.data?.row;
      const temp = new Set();
      staticDB.forEach((element) => {
        temp.add(element.BATCH_LOT_NO);
      });
      staticBat = Array.from(temp);
      setPageNumbers(() => {
        let count = [];
        for (
          let index = 0;
          index < Math.ceil(data?.data?.data?.row.length / pageLimit);
          index++
        ) {
          count.push(index + 1);
        }
        return count;
      });
    },
    onError: (error) => {
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        navigate("/", { state: { from: location }, replace: true });
      } else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
      setEventList([]);
    },
  });
  function handleDate(date) {
    if (date == null) return "N/A";
    const validDate = parseISO(date, "yyyy-MM-dd", new Date());
    if (isValid(validDate))
      return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
    return "N/A";
  }

  return (
    <div className=" overflow-x-hidden">
      {getSgtin.isSuccess && <CSVDownload {...sgtinCsvReport} target="_self" />}
      <Card className=" overflow-x-hidden">
        <CardHeader>
          <CardTitle className="my-[10px] flex justify-between items-center p-[20px]">
            {" "}
            <span className=" flex gap-[20px] items-center">
              <span>SGTIN Generation Tab</span>
              <span>
                {" "}
                <Sheet>
                  <SheetTrigger asChild>
                    <Button variant="outline">Generate Label</Button>
                  </SheetTrigger>
                  <SheetContent className=" min-w-[550px]">
                    <SheetHeader>
                      <SheetTitle>Genearate Label</SheetTitle>
                      <SheetDescription>
                        Enter the required GTIN details here. Click submit when
                        you're done.
                      </SheetDescription>
                    </SheetHeader>
                    <SgtinModal longitude={longitude} latitude={latitude} />
                  </SheetContent>
                </Sheet>
              </span>
            </span>
            <span>
              <Button onClick={handleDownload} disabled={getSgtin.isLoading}>
                {getSgtin.isLoading ? (
                  <ClipLoader color="primary" size={20} />
                ) : (
                  <Download />
                )}
              </Button>
            </span>
          </CardTitle>
          <CardDescription className="flex gap-[10px]">
            <Popover open={openclient} onOpenChange={setOpenClient}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={openclient}
                  className="w-[200px] justify-between">
                  {valueClient ? valueClient : "Pick a client..."}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandInput placeholder="Search client..." />
                  <CommandEmpty>No framework found.</CommandEmpty>
                  <CommandGroup>
                    {allClients.map((client) => (
                      <CommandItem
                        key={client.id}
                        value={client?.name}
                        onSelect={(currentValue) => {
                          setClientValue(currentValue);
                          handleChange(client.key);
                          setOpenClient(false);
                        }}>
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            valueClient === client?.name
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {client.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>

            <Popover open={openProd} onOpenChange={setOpenProd}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={openProd}
                  className="w-[200px] justify-between">
                  {prodValue
                    ? prods.find((prod) => prod.product_name === prodValue)
                        ?.product_name
                    : "Pick a product..."}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandInput placeholder="Search product..." />
                  <CommandEmpty>No framework found.</CommandEmpty>
                  <CommandGroup>
                    {prods.map((prod, index) => (
                      <CommandItem
                        key={index}
                        value={prod.product_name}
                        onSelect={(currentValue) => {
                          setProdValue(currentValue);
                          setOpenProd(false);
                        }}>
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            prodValue === prod.product_name
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {prod.product_name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
            <Popover open={openLabel} onOpenChange={setOpenlabel}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={openLabel}
                  className="w-[200px] justify-between">
                  {labelValue ? labelValue : "Pick Batch/lot..."}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandInput placeholder="Search label type..." />
                  <CommandEmpty>No batch/lot found.</CommandEmpty>
                  <CommandGroup>
                    {staticBat.map((framework) => (
                      <CommandItem
                        key={framework}
                        value={framework}
                        onSelect={(currentValue) => {
                          setLabelalue(
                            currentValue === labelValue ? "" : currentValue
                          );

                          setOpenlabel(false);
                        }}>
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            labelValue === framework
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {framework}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-[280px] justify-start text-left font-normal",
                    !startdate && "text-muted-foreground"
                  )}>
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {startdate ? (
                    format(startdate, "PPP")
                  ) : (
                    <span>Start date</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={startdate}
                  onSelect={setSatrtdate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-[280px] justify-start text-left font-normal",
                    !enddate && "text-muted-foreground"
                  )}>
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {enddate ? format(enddate, "PPP") : <span>End date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={enddate}
                  onSelect={setEndddate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <Button onClick={handleParams}>Search</Button>
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-2">
          <Table className=" overflow-x-hidden">
            <TableCaption>A list of all generated SGTINs labels.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead></TableHead>
                <TableHead>Product Name</TableHead>
                <TableHead>Client Name</TableHead>
                <TableHead>Date-Generated</TableHead>
                <TableHead>Generated By</TableHead>
                <TableHead>BATCH/LOT</TableHead>
                <TableHead className="text-right whitespace-nowrap ">
                  EXpiry Date
                </TableHead>
                <TableHead className="text-right whitespace-nowrap">
                  MFG Date
                </TableHead>
                <TableHead>Volume</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div className="flex justify-center">
                      <ClipLoader />
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {tabArr.map(
                (event) =>
                  !event?.BATCH_LOT_NO.includes("test") && (
                    <TableRow key={event.id}>
                      <TableCell>
                        <input
                          style={{ cursor: "pointer" }}
                          type="radio"
                          name="sgtinTrace"
                          onChange={(e) => {
                            setTraceId(event.traceId);
                            setElemTarget(e);
                          }}
                        />
                      </TableCell>
                      <TableCell className=" whitespace-nowrap">
                        {event?.clientName}
                      </TableCell>
                      <TableCell className=" whitespace-nowrap">
                        {event?.prodName}
                      </TableCell>
                      <TableCell className=" whitespace-nowrap">
                        {" "}
                        {handleDate(event?.createdAt)}
                      </TableCell>
                      <TableCell className=" whitespace-nowrap">
                        {" "}
                        {event?.createdBy}
                      </TableCell>
                      <TableCell className=" whitespace-nowrap">
                        {event?.BATCH_LOT_NO}
                      </TableCell>
                      <TableCell className="text-right">
                        {event?.EXP_DATE}
                      </TableCell>
                      <TableCell className="text-right">
                        {event?.MFG_DATE}
                      </TableCell>
                      <TableCell className="">{event?.count}</TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={9}>Total</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={9}>
                  <Pagination>
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          className={`cursor-pointer ${
                            currentPage === 0 && "disable-btn"
                          }`}
                          onClick={() => handlePageChange("prev")}
                        />
                      </PaginationItem>
                      {pageNumbers.map((page, index) => (
                        <PaginationItem
                          key={index}
                          className={"cursor-pointer"}>
                          <PaginationLink
                            onClick={() => setCurrentPage(page)}
                            isActive={currentPage === page}>
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationEllipsis />
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationNext
                          className={`cursor-pointer ${
                            currentPage === pageNumbers.length && "disable-btn"
                          }`}
                          onClick={() => handlePageChange("next")}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
}

export default Sgtin;
