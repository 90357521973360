import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "../../components/ui/dialog";

import { Button } from "../../components/ui/button";
import { Eye } from "lucide-react";
import { format, isValid, parseISO } from "date-fns";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  // PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";

import { useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../../components/ui/sheet";
import { useToast } from "../../components/ui/use-toast";
import TraceMap from "../../components/TraceMap";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
let pageCount = 0;
let total_row = 0;
function ShipTab() {
  const [open, setOpen] = useState(false);
  const [openDig, setOpenDig] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { allClients } = useAuth();
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [prods, setProds] = useState([]);
  const [parent, setParent] = useState();
  const [barcode, setBarcode] = useState();
  // const [downloadData, setDownloadData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let [pageNumbers, setPageNumbers] = useState([]);
  const getProductsbyKey = async (key) => {
    const response = await axiosPrivate.get(`client/products/${key.clientKey}`);
    return response;
  };
  const getEventData = async () => {
    const response = await axiosPrivate.get(
      `label/ship/table/?currentPage=${currentPage}&startdate=${params.startate}&enddate=${params.enddate}&source=${params.source}&destination=${params.destination}&clientKeys=${params.clientName}&prodIds=${params.prodName}`
    );
    return response;
  };

  // const downloadEncodedData = async (data) => {
  //   const response = await axiosPrivate.post("label/commission/download", data);
  //   return response;
  // };

  const handleChange = async (key) => {
    try {
      const prodResponse = await getProductsbyKey({ clientKey: key });
      setProds([...prodResponse.data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const [params, setParams] = useState({
    startate: "2023-01-01",
    enddate: format(new Date(), "yyyy-MM-dd"),
    clientName: "all",
    prodName: "all",
    source: "all",
    destination: "all",
  });

  // const header = [
  //   { key: "prodName", label: "product Name" },
  //   { key: "clientName", label: "Client Name" },
  //   { key: "labelType", label: "Label-Type" },
  //   { key: "count", label: "Volume" },
  //   { key: "createdAt", label: "Date" },
  // ];

  const { refetch, isFetching } = useQuery({
    queryKey: ["item-shipped", currentPage],
    queryFn: () => getEventData(),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      pageCount = data?.data?.data?.pageCount;
      setEventList(data?.data?.data?.row);
      total_row = data?.data?.data?.total;
    },
    onError: (error) => {
      console.log(error);
      if (error.response?.status === 403 || error.response?.status === 401) {
        navigate("/");
      }
      setEventList([]);
    },
  });

  // const download = useMutation((variables) => downloadEncodedData(variables), {
  //   onSuccess(data, variables, context) {
  //     setDownloadData(
  //       data?.data?.data.map((event) => {
  //         return {
  //           prodName: event.prodName,
  //           clientName: event.clientName,
  //           count: event.count,
  //           createdAt: format(new Date(event.createdAt), "yyyy-MM-dd hh:mm:ss"),
  //         };
  //       })
  //     );
  //   },
  //   onError(error) {
  //     console.log(error);
  //   },
  // });

  // function handleLabelDownload() {
  //   download.mutate(params);
  // }

  function handleParams() {
    setCurrentPage(1);
    refetch();
  }

  function handlePageChange(dir) {
    if (dir === "next") {
      const lastIndex = pageNumbers[pageNumbers.length - 1];
      const firstIndex = pageNumbers[0];
      if (currentPage < pageCount) {
        if (currentPage >= lastIndex) {
          let newArray = pageNumbers;
          newArray.push(lastIndex + 1);
          newArray.shift(firstIndex);
          setPageNumbers(newArray);
          setCurrentPage((prev) => prev + 1);
        } else {
          setCurrentPage((prev) => prev + 1);
        }
      }
    }
    if (dir === "prev") {
      const lastIndex = pageNumbers[pageNumbers.length - 1];
      const firstIndex = pageNumbers[0];
      if (currentPage > 1) {
        if (currentPage <= firstIndex) {
          let newArray = pageNumbers;
          newArray.unshift(firstIndex - 1);
          newArray.pop(lastIndex);
          setPageNumbers(newArray);
          setCurrentPage((prev) => prev - 1);
        } else {
          setCurrentPage((prev) => prev - 1);
        }
      }
    }
  }
  function handleDate(date) {
    if (date == null) return "N/A";
    const validDate = parseISO(date, "yyyy-MM-dd", new Date());
    if (isValid(validDate))
      return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
    return "N/A";
  }
  const handleTraceModal = (e, parent) => {
    e.preventDefault();
    setOpen(true);
    console.log(parent);
    setParent(parent);
  };
  useEffect(() => {
    const newArray = [];
    for (let i = 0; i < pageCount; i++) {
      newArray.push(i + 1);
    }
    pageCount < 10
      ? setPageNumbers(newArray)
      : setPageNumbers([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  }, []);

  return (
    <>
      <Dialog open={openDig} onOpenChange={setOpenDig}>
        <DialogContent>
          <DialogHeader>
            <DialogDescription>
              <TraceMap barcode={barcode} />
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Pack Content</SheetTitle>
            <SheetDescription>
              <span>Parent: </span>
              <Button
                className=" text-[light-green]"
                variant="link"
                onClick={() => {
                  setOpenDig(true);
                  setBarcode(parent);
                }}>
                {parent}
              </Button>
              <hr />
              <br />
            </SheetDescription>
          </SheetHeader>
          <Traces
            parent={parent}
            setOpenDig={setOpenDig}
            setBarcode={setBarcode}
          />
        </SheetContent>
      </Sheet>
      <div className=" overflow-x-hidden">
        <Card className=" overflow-x-hidden">
          <CardHeader>
            <CardTitle className="my-[10px]">Items Shiped</CardTitle>
            <CardDescription>
              <div className=" w-[100%] flex gap-10 mb-5">
                <Select
                  className=" w-[50%] flex-1"
                  // isMulti
                  onChange={(e) => {
                    handleChange(e.value);
                    setParams({ ...params, clientName: e.value });
                  }}
                  // placeholder="Select user roles"
                  options={[
                    { value: "all", label: "all" },
                    ...allClients?.map((client) => {
                      return { value: client?.key, label: client?.name };
                    }),
                  ]}
                />
                <Select
                  className=" w-[50%] flex-1"
                  // isMulti
                  onChange={(e) => setParams({ ...params, prodName: e.value })}
                  // placeholder="Select user roles"
                  options={[
                    ...prods.map((prod, index) => {
                      return { value: prod.id, label: prod.prod_name };
                    }),
                  ]}
                />
              </div>
              <div className="w-[100%] flex align-middle gap-10">
                <input
                  type="date"
                  name="start-date"
                  id=""
                  className="w-60 border-2 cursor-pointer"
                  onChange={(e) =>
                    setParams({ ...params, startate: e.target.value })
                  }
                />
                <input
                  type="date"
                  name="end-date"
                  id=""
                  onChange={(e) =>
                    setParams({ ...params, enddate: e.target.value })
                  }
                  className="w-60 border-2 cursor-pointer"
                />
                <Button onClick={handleParams}>Search</Button>
                {/* <Button onClick={handleLabelDownload}>Download</Button> */}
              </div>
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            <Table className=" overflow-x-hidden min-h-36">
              <TableCaption>A list of all recent shipments.</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Client Name</TableHead>
                  <TableHead>Product Name</TableHead>
                  <TableHead>Date Shupped</TableHead>
                  <TableHead>Source</TableHead>
                  <TableHead>Destination</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Date Recieved</TableHead>
                  <TableHead>View Details</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isFetching && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <div className="flex justify-center">
                        <ClipLoader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {eventList.map((event) => (
                  <TableRow key={event.id}>
                    <TableCell className="whitespace-nowrap">
                      {event?.clientName}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {event?.prodName}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {handleDate(event?.createdAt)}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {event?.source}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {event?.destinationName}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {" "}
                      {event.received === 0 ? "IN-TRANSIST" : "RECEIVED"}
                    </TableCell>

                    <TableCell className="whitespace-nowrap">
                      {handleDate(event?.receivedAt)}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      <Eye
                        className="detail-view"
                        onClick={(e) => {
                          handleTraceModal(e, event?.elementString);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>Total Rows</TableCell>
                  <TableCell colSpan={1} className=" justify-end flex">
                    {total_row}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}></TableCell>
                  <TableCell colSpan={3}>
                    <Pagination>
                      <PaginationContent>
                        <PaginationItem>
                          <PaginationPrevious
                            className={`cursor-pointer ${
                              currentPage <= 1 ? "disable-btn" : ""
                            }    ${isFetching && "disable-btn"}`}
                            onClick={() => handlePageChange("prev")}
                          />
                        </PaginationItem>
                        {/* {pageNumbers.map((page, index) => (
                          <PaginationItem
                            key={index}
                            className={"cursor-pointer"}>
                            <PaginationLink
                              onClick={() => {
                                setCurrentPage(page);
                              }}
                              isActive={currentPage === page}>
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))} */}
                        <PaginationItem>
                          <PaginationEllipsis />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationNext
                            className={`cursor-pointer ${
                              currentPage < pageCount ? "" : "disable-btn"
                            } ${isFetching && "disable-btn"}`}
                            onClick={() => {
                              handlePageChange("next");
                            }}
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </>
  );
}

function Traces({ parent, setOpenDig, setBarcode }) {
  const { toast } = useToast();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchPackContent = async (barcode) => {
    const response = await axiosPrivate.get(`/label/content/${barcode}`);
    return response;
  };
  const { data, isSuccess } = useQuery(
    "packContent",
    () => {
      return fetchPackContent(parent);
    },
    {
      onError: (error) => {
        if (error.code === "ERR_NETWORK") {
          navigate("/", { state: { from: location }, replace: true });
        } else if (error.response.status === 401)
          navigate("/", { state: { from: location }, replace: true });
        else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        }
      },
    }
  );
  return (
    <ul>
      {isSuccess &&
        data.data.data.length > 0 &&
        data.data.data.map((item, index) => {
          return (
            <li key={index}>
              <Button
                className=" text-[12px]"
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDig(true);
                  setBarcode(item.elementString);
                  // setTraceBarcode(item.elementString);
                }}>
                {item.elementString}
              </Button>
            </li>
          );
        })}
    </ul>
  );
}
export default ShipTab;
