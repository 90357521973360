import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { Progress } from "../components/ui/progress";
import { useToast } from "../components/ui/use-toast";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState(10);
  const { toast } = useToast();
  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
        if (err.code === "ERR_NETWORK")
          navigate("/", { state: { from: location }, replace: true });
        else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${err?.response?.data?.message}`,
          });
          navigate("/", { state: { from: location }, replace: true });
        }
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 5);
      if (progress === 100) setProgress(10);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="w-full h-[80vh] flex justify-center items-center flex-col gap-[20px]">
          {" "}
          <img srcSet="/sproxillogo.png" alt="" style={{ width: "110px" }} />
          <Progress value={progress} className="w-[30%]" />
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
