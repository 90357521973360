import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Label } from "./ui/label";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./ui/command";
import { z } from "zod";
import { useMutation } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "./ui/use-toast";
import { ClipLoader } from "react-spinners";
import useAuth from "../hooks/useAuth";
import { cn } from "../lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
function SgtinModal({ latitude, longitude }) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { allClients } = useAuth();
  const { toast } = useToast();
  const [readpoint, setReadpoint] = useState([]);
  const [prodName, setProdName] = useState();
  const [loading, setLoading] = useState(false);
  const [prods, setProds] = useState([]);
  const formSchema = z.object({
    clientKey: z.string({
      message: "This field is required",
    }),
    gtin: z.string({
      message: "This field is required",
    }),
    readpoint: z.string({
      message: "This field is required",
    }),
    expiryDate: z.string({
      message: "This field is required",
    }),
    mfgDate: z.string({
      message: "This field is required",
    }),
    qty: z.string({
      message: "This field is required",
    }),
    tag: z.string({
      message: "This field is required",
    }),
    lotNumber: z.string({
      message: "This field is required",
    }),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      clientKey: "",
      gtin: "",
      readpoint: "",
      qty: "",
      tag: "",
      lotNumber: "",
    },
  });
  function onSubmit(data) {
    const payload = {
      eventObjectParameter: {
        eventTime: new Date().toISOString(),
        recordTime: new Date().toISOString(),
        latitude: latitude,
        longitude: longitude,
        readPoint: data.readpoint,
        timeZone: "+01:00",
        bizStep: "urn:epcglobal:cbv:bizstep:encoding",
        disposition: "urn:epcglobal:cbv:disp:active",
      },

      gtin: data.gtin.substring(0, data.gtin.indexOf(",")),
      clientKey: data.clientKey,
      qty: parseInt(data.qty),
      lotNumber: data.lotNumber,
      expiryDate: new Date(data.expiryDate),
      mfgDate: new Date(data.mfgDate),
      tag: data.tag,
      prodId: data.gtin.substring(
        data.gtin.indexOf(",") + 1,
        data.gtin.lastIndexOf(",")
      ),
      prodName: data.gtin.substring(data.gtin.lastIndexOf(",") + 1),
    };
    // console.log(payload);
    setLoading(true);
    mutate(payload);
  }

  const submitSGTIN = async (data) => {
    return await axiosPrivate.post(`queues/sgtin`, data);
  };

  const { mutate } = useMutation((variables) => submitSGTIN(variables), {
    onSuccess(data, variables, context) {
      toast({
        variant: "success",
        title: "Success",
        description: `${data.data.message}`,
      });
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (error.response.status === 401)
        navigate("/", { state: { from: location }, replace: true });
      else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    },
  });

  const getlocationbyKey = async (key) => {
    const response = await axiosPrivate.get(`client/location/${key.clientKey}`);
    return response;
  };

  const getProductsbyKey = async (key) => {
    const response = await axiosPrivate.get(`client/products/${key.clientKey}`);
    return response;
  };

  const handleChange = async (key) => {
    try {
      const readpointResponse = await getlocationbyKey({ clientKey: key });
      setReadpoint([...readpointResponse.data.data]);
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (error.response.status === 401)
        navigate("/", { state: { from: location }, replace: true });
      else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    }

    try {
      const prodResponse = await getProductsbyKey({ clientKey: key });
      console.log(prodResponse.data.data);
      setProds([...prodResponse.data.data]);
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        navigate("/", { state: { from: location }, replace: true });
      } else if (error.response.status === 401)
        navigate("/", { state: { from: location }, replace: true });
      else {
        toast({
          variant: "destructive",
          title: "Error",
          description: `${error?.response?.data?.message}`,
        });
      }
    }
  };

  return (
    <div>
      <div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="clientKey"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Clients</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-[100%] justify-between",
                            !field.value && "text-muted-foreground"
                          )}>
                          {field.value
                            ? allClients?.find(
                                (client) => client?.key === field.value
                              )?.name
                            : "Select client"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className=" w-[100%]">
                      <Command>
                        <CommandInput placeholder="Search location.." />
                        <CommandEmpty>No client found.</CommandEmpty>
                        <CommandGroup>
                          {allClients.map((client) => (
                            <CommandItem
                              value={client?.key}
                              key={client.key}
                              onSelect={() => {
                                form.setValue("clientKey", client?.key);
                                handleChange(client?.key);
                              }}>
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  client.key === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {client.name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="readpoint"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Locations</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-[100%] justify-between",
                            !field.value && "text-muted-foreground"
                          )}>
                          {field.value
                            ? readpoint?.find((loc) => loc?.gln === field.value)
                                ?.address
                            : "Select your location"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className=" w-[100%] p-0">
                      <Command>
                        <CommandInput placeholder="Search location.." />
                        <CommandEmpty>No locaton found.</CommandEmpty>
                        <CommandGroup>
                          {readpoint.map((loc) => (
                            <CommandItem
                              value={loc?.gln}
                              key={loc?.gln}
                              onSelect={() => {
                                form.setValue("readpoint", loc?.gln);
                              }}>
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  loc?.gln === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {loc?.address}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="gtin"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>GTINs</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-[100%] justify-between",
                            !field.value && "text-muted-foreground"
                          )}>
                          {prodName ? prodName : "Select Gtin"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className=" w-[100%] p-0">
                      <Command>
                        <CommandInput placeholder="Search products...." />
                        <CommandEmpty>No GTIN found.</CommandEmpty>
                        <CommandGroup>
                          {prods.map((prod) => (
                            <CommandItem
                              value={`${prod.gtin},${prod.id},${prod.prod_name}`}
                              key={prod?.id}
                              onSelect={() => {
                                setProdName(prod.prod_name);
                                form.setValue(
                                  "gtin",
                                  `${prod.gtin},${prod.id},${prod.prod_name}`
                                );
                              }}>
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  prod?.prod_name === prodName
                                    ? // field.value.split(",").include(prod.prod_name)
                                      "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {prod?.prod_name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-[10px]">
              <FormField
                control={form.control}
                name="lotNumber"
                render={({ field }) => (
                  <FormItem>
                    <Label>LOT/BATCH NO.</Label>
                    <FormControl>
                      <Input
                        className="flex-3"
                        type="text"
                        placeholder={"lot/batch number"}
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="qty"
                render={({ field }) => (
                  <FormItem>
                    <Label>Volume</Label>
                    <FormControl>
                      <Input
                        className="flex-2"
                        type="number"
                        placeholder={"set volume"}
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tag"
                render={({ field }) => (
                  <FormItem>
                    <Label>Tag</Label>
                    <FormControl>
                      <Input
                        className="flex-2"
                        type="text"
                        placeholder={"Set Tag"}
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex gap-[20px]">
              <FormField
                control={form.control}
                name="expiryDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Expiry Date</FormLabel>
                    <FormControl>
                      <Input
                        className="flex-6"
                        type="date"
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    {/* <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-[240px] pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}>
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover> */}
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="mfgDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>MFG Date</FormLabel>
                    <FormControl>
                      <Input
                        className="flex-6"
                        type="date"
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    {/* <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-[240px] pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}>
                            {field.value ? (
                              format(field.value, "yyyy-MM-dd")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover> */}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Button type="submit" disabled={loading}>
              {loading ? (
                <span className="mx-[20px]">
                  <ClipLoader color="primary" size={20} />
                </span>
              ) : (
                <span>Submit</span>
              )}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}

export default SgtinModal;
