import { AreaChart, Landmark, Layers3, UsersRound } from "lucide-react";
import React, { useState } from "react";
import {
  NavLink,
  useLocation,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { Button } from "./ui/button";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useToast } from "../components/ui/use-toast";
import { ClipLoader } from "react-spinners";
import { useVerifyUserRole } from "../verifyRoles";
import { roles } from "../roles";
function SideBar() {
  const verifyRoles = useVerifyUserRole();
  const axiosprivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const path = useResolvedPath();
  const { toast } = useToast();
  const pathName = path.pathname;
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const location = useLocation();
  const handleLogOut = async () => {
    setIsLoading(true);
    try {
      await axiosprivate.get("log-out");
      setAuth({});
      setIsLoading(false);
      navigate("/", { state: { from: location }, replace: true });
    } catch (error) {
      setIsLoading(false);
      toast({
        variant: "destructive",
        title: "Error",
        description: `Some thing went wrong. Try again later`,
      });
    }
  };

  return (
    <section
      id="side-bar"
      className=" sticky top-0 left-0 h-[100vh] flex-shrink-0">
      <div
        id="wrapper"
        className=" h-full py-[20px] px-[10px] bg-[rgba(120,0,0,255)]">
        <div className=" min-h-[55%]">
          <ul className=" list-none py-[20px] px-[10px] text-primary-foreground no-underline">
            <li
              className={`px-[10px] py-[8px] my-[15px]  ${
                pathName === "/pages" &&
                "border-[1px]  rounded-tl-[10px] rounded-br-[10px]"
              }   ${true ? " pointer-events-all" : " pointer-events-none"} ${
                verifyRoles(roles.all_roles) ? " opacity-100" : " opacity-40"
              }`}>
              <NavLink to={"/pages"} className={"link"}>
                {" "}
                <span>
                  <AreaChart />
                </span>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li
              className={`px-[10px] py-[8px] my-[15px] ${
                pathName === "/pages/client" &&
                "border-[1px]  rounded-tl-[10px] rounded-br-[10px]"
              }
               ${
                 verifyRoles(roles.sproxil_admin)
                   ? " pointer-events-all"
                   : " pointer-events-none"
               } ${
                verifyRoles(roles.sproxil_admin)
                  ? " opacity-100"
                  : " opacity-40"
              }
              `}>
              <NavLink to={"/pages/client"} className={"link"}>
                {" "}
                <span>
                  <Landmark />
                </span>
                <span>Clients</span>
              </NavLink>
            </li>
            <li
              className={`px-[10px] py-[8px] my-[15px] ${
                pathName === "/pages/user" &&
                "border-[1px]  rounded-tl-[10px] rounded-br-[10px]"
              }
               ${
                 verifyRoles(roles.sproxil_admin)
                   ? " pointer-events-all"
                   : " pointer-events-none"
               } ${
                verifyRoles(roles.sproxil_admin)
                  ? " opacity-100"
                  : " opacity-40"
              }
              `}>
              <NavLink to={"/pages/user"} className={"link"}>
                {" "}
                <span>
                  <UsersRound />
                </span>
                <span>Users</span>
              </NavLink>
            </li>
            <li
              className={`px-[10px] py-[8px] my-[15px] ${
                pathName === "/pages/serialization" &&
                "border-[1px]  rounded-tl-[10px] rounded-br-[10px]"
              }
               ${
                 verifyRoles(roles.encodingRole)
                   ? " pointer-events-all"
                   : " pointer-events-none"
               } ${
                verifyRoles(roles.encodingRole) ? " opacity-100" : " opacity-40"
              }
              `}>
              <NavLink to={"/pages/serialization"} className={"link"}>
                {" "}
                <span>
                  <Layers3 />
                </span>
                <span>Serialization</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className=" flex justify-center text-[13px] tracking-widest">
          <Button
            onClick={handleLogOut}
            disable={isLoading}
            variant="outline"
            className="px-[30px]">
            {isLoading ? (
              <span className="px-[20px]">
                <ClipLoader color="primary" size={20} />
              </span>
            ) : (
              <span> Log-Out</span>
            )}
          </Button>
        </div>
      </div>
    </section>
  );
}

export default SideBar;
